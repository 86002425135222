import { initFontAwesome } from "@solvari/common-fe/integrations";

import "@solvari/components/blade/s-checkbox-dropdown/sCheckboxDropdown";
import "@/admin/utils/sentry";

import "@solvari/components/blade/review/review-widget/reviewScore.css";
import "@solvari/components/blade/pro/chosen.css";
import "@solvari/components/blade/pro/s-company-badges/sCompanyBadge.css";
import "@solvari/components/blade/pro/s-company-card/sCompanyCard.css";

initFontAwesome();
