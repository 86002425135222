import type { BrowserOptions } from "@sentry/vue";
import type { App } from "vue";

import * as Sentry from "@sentry/vue";

import { getEnv } from "@solvari/utils";

import type { SentryRootProject } from "@/lib/integrations/sentry/types";

import { isBot, isSupportedBrowser } from "@/lib/helpers/isSupportedBrowser";
import { projects, rootProjects } from "@/lib/integrations/sentry/projects.ts";
import {
  solvariContextIntegration,
  solvariFilterIntegration,
} from "@/lib/integrations/sentry/service/contextIntegration";
import { ignoreConfig } from "@/lib/integrations/sentry/service/ignored";
import { getProjectRelease } from "@/lib/integrations/sentry/service/project";
import { trackImageLoading } from "@/lib/integrations/sentry/service/trackers";

/*
  You can only have one Sentry instance running on a page.
  We use micro frontends that each have their own Sentry project.
  So we need some logic to send the errors to the right project by defining them globally
 */

function makeMicroFrontEndTransport() {
  return Sentry.makeMultiplexedTransport(
    Sentry.makeFetchTransport,
    ({ getEvent }) => {
      if (!window.sentryProjects) {
        return [];
      }
      const projectName = getEvent()?.tags?.project as string | undefined;
      return projectName && projectName in projects
        ? [projects[projectName as keyof typeof projects]]
        : [];
    },
  );
}

function getConfig(rootProject: SentryRootProject): BrowserOptions {
  return {
    dsn: rootProjects[rootProject.name],
    environment: rootProject.environment,
    transport: makeMicroFrontEndTransport(),
    release: getProjectRelease(rootProject),
    integrations: [
      Sentry.extraErrorDataIntegration(),
      Sentry.httpClientIntegration(),
      solvariFilterIntegration(),
      solvariContextIntegration(rootProject),
    ],
    sendDefaultPii: true,
    replaysOnErrorSampleRate: 0.1,
    ...ignoreConfig,
  };
}

function initSentryRoot(rootProject: SentryRootProject) {
  if (!isSupportedBrowser() || isBot() || getEnv().config.envBE === "local") {
    return;
  }

  Sentry.init({
    ...getConfig(rootProject),
    integrations: (integrations) =>
      integrations.filter((integration) => integration.name !== "Vue"),
  });

  function trackVueErrors(app: App) {
    Sentry.addIntegration(Sentry.vueIntegration({ app }));
  }

  window.sentry = {
    captureException: Sentry.captureException,
    captureMessage: Sentry.captureMessage,
    trackVueErrors,
  };

  trackImageLoading();
}

export { initSentryRoot };
